<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <SearchBar
        search-label="Search rules"
        @onSearch="onSearch"
        :is-loading="loading"
      />
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        :options.sync="options"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.title="{ item }">
          <span v-if="item.deletable" class="form-link" @click="open(item)">
            {{ item.title }}
          </span>
          <span v-else>
            {{ item.title }}
          </span>
        </template>

        <template v-slot:item.severity="{ item }">
          <v-icon :class="severityClass(item.severity)" small>
            mdi-view-grid
          </v-icon>
          <span class="ml-1">{{ severityName(item.severity) }}</span>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <!--NAME-->
                    <td
                      v-if="expandItem.value === 'title'"
                      width="150"
                      class="pl-3"
                    >
                      Name:
                    </td>
                    <td v-if="expandItem.value === 'title'">
                      <span
                        v-if="item.deletable"
                        class="form-link"
                        @click="open(item)"
                      >
                        {{ item.title }}
                      </span>
                      <span v-else>{{ item.title }} </span>
                    </td>
                    <!--END NAME-->

                    <!--XPATH-->
                    <td
                      v-if="expandItem.value === 'xpath'"
                      width="150"
                      class="pl-3"
                    >
                      Xpath:
                    </td>
                    <td v-if="expandItem.value === 'xpath'">
                      {{ item.xpath ? item.xpath : "-" }}
                    </td>
                    <!--END XPATH-->

                    <!--CIS-->
                    <td
                      v-if="expandItem.value === 'cis_id'"
                      width="150"
                      class="pl-3"
                    >
                      CIS ID:
                    </td>
                    <td v-if="expandItem.value === 'cis_id'">
                      {{ item.cis_id ? item.cis_id : "-" }}
                    </td>
                    <!--END CIS-->

                    <!--OUTPUT-->
                    <td
                      v-if="expandItem.value === 'expected_output'"
                      width="150"
                      class="pl-3"
                    >
                      Expected output:
                    </td>
                    <td v-if="expandItem.value === 'expected_output'">
                      {{ item.expected_output ? item.expected_output : "-" }}
                    </td>
                    <!--END OUTPUT-->

                    <!--CONDITIONS-->
                    <td
                      v-if="expandItem.value === 'condition_second'"
                      width="150"
                      class="pl-3"
                    >
                      Second conditions:
                    </td>
                    <td v-if="expandItem.value === 'condition_second'">
                      {{ item.condition_second ? item.condition_second : "-" }}
                    </td>
                    <!--END CONDITIONS-->

                    <!--FIX-->
                    <td
                      v-if="expandItem.value === 'fix'"
                      width="150"
                      class="pl-3"
                    >
                      Fix:
                    </td>
                    <td v-if="expandItem.value === 'fix'">
                      {{ item.fix ? item.fix : "-" }}
                    </td>
                    <!--END FIX-->

                    <!--CLI-->
                    <td
                      v-if="expandItem.value === 'cli'"
                      width="150"
                      class="pl-3"
                    >
                      Cli:
                    </td>
                    <td v-if="expandItem.value === 'cli'">
                      {{ item.cli ? item.cli : "-" }}
                    </td>
                    <!--END CLI-->

                    <!--SEVERITY-->
                    <td
                      v-if="expandItem.value === 'severity'"
                      width="150"
                      class="pl-3"
                    >
                      Severity:
                    </td>
                    <td v-if="expandItem.value === 'severity'">
                      <v-icon :color="severityColor(item.severity)" small>
                        fas fa-circle
                      </v-icon>
                      <span class="ml-1">{{
                        severityName(item.severity)
                      }}</span>
                    </td>
                    <!--END SEVERITY-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
import permission from "@/directive/permission/permission.js";
import SearchBar from "@/components/SearchBar";
export default {
  directives: { permission },
  components: {
    SearchBar,
  },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],
      options: {},
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,
      order: null,
      by: null,
      deleteMenu: false,
      cloneMenu: false,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    auditorTemplateRulesTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.options.page = newValue;
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.options.itemsPerPage = this.sizes[newValue];
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue.sortBy &&
          newValue.sortDesc &&
          newValue.sortBy.length > 0 &&
          newValue.sortDesc.length > 0
        ) {
          const order = newValue.sortDesc[0] ? "desc" : "asc";
          const by = newValue.sortBy[0];
          this.$emit("change", {
            page: this.page,
            size: this.sizes[this.size],
            order: order,
            orderBy: by,
          });
        }
      },
    },
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "auditorTemplateRule") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
  },
  created() {
    this.location = window.location.origin;
  },
  methods: {
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "auditorTemplateRule";
    },
    onSearch(event) {
      this.$emit("onSearch", event);
    },
    severityName(status) {
      if (status === 1) {
        return "Low";
      } else if (status === 2) {
        return "Medium";
      } else if (status === 3) {
        return "High";
      } else if (status === 4) {
        return "Critical";
      } else {
        return "Undefined";
      }
    },
    severityColor(status) {
      if (status === 1) {
        return "neutral-3";
      } else if (status === 2) {
        return "good-3";
      } else if (status === 3) {
        return "medium-3";
      } else if (status === 4) {
        return "bad-3";
      } else {
        return "neutral-5";
      }
    },
    severityClass(status) {
      if (status === 0 || status === "0") {
        return "signal-icon--undefined";
      } else if (status === 1 || status === "1") {
        return "signal-icon--low";
      } else if (status === 2 || status === "2") {
        return "signal-icon--medium";
      } else if (status === 3 || status === "3") {
        return "signal-icon--high";
      } else {
        return "signal-icon--critical";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
